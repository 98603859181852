import { Auth } from 'aws-amplify'

export default class loginSession {
	//get jwtToken for api calls
	getToken(){
		const promise = new Promise((resolve, reject) => {
			Auth.currentSession().then((data)=>{
				resolve(data.idToken.jwtToken)
			})
			.catch ( (err)=> {
				reject(err)
			})
		})
		return promise
	}
	
	signOut(){
		const promise = new Promise((resolve, reject) => {
			Auth.signOut().then((data)=>{
				resolve(data)
			})
			.catch ( (err)=> {
				reject(err)
			})
		})
		return promise
	}
}
