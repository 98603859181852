import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from 'react';
import "./style.css"

import logo from "../../images/logo-mobile.png"
import menuclose from "../../images/menu-close.png"

class NavBar extends Component {
	state = {
		activeUrl: ""
	};
	render() {
		return (
			<ul className={"navbar-nav"+this.props.suffix+" "+this.props.type + this.props.suffix + "-nav navbar-menu-"+this.props.fadeOption}>
			{this.props.hasMenu && <li className={"navbar-close"+this.props.suffix+" "+this.props.type + this.props.suffix + "-close"}>
				<img src={logo} alt="Menu" className={"navbar-close-logo " +this.props.type + this.props.suffix + "-close-logo"}/>
				<button className={"navbar-close " +this.props.type + this.props.suffix + "-close"} onClick={this.props.closeHandler}><img src={menuclose} alt="Close" /></button>
			</li>}
			{this.props.items.map((entry,i) =>
				<li key={this.props.type + this.props.suffix+entry.url+i} className={(this.props.activePath===entry.url?"active ":"") + "navbar-nav"+this.props.suffix+" "+this.props.type + this.props.suffix + "-nav"}>
					{this.props.hasMenu === false && 
						<Link 
							to={"/"+entry.url}
							className={"font-style-bold navbar-menu"+this.props.suffix+" navbar-nav"+this.props.suffix+" "+this.props.type + this.props.suffix + "-nav"+(this.props.fullPath === entry.url?" active":"")}
							activeClassName="active"
						>
							<div className={"navbar-nav-aligner"}></div>
								<span className={"navbar-nav-aligner"}>{entry.title}</span>
							<div className={"navbar-nav-aligner"}></div>
						</Link>}
					{this.props.hasMenu && !entry.subitems && 
						<button 
							onClick={() => navigate("/"+entry.url) }
							className={"font-style-bold navbar-menu"+this.props.suffix+" navbar-button"+this.props.suffix+" "+this.props.type + this.props.suffix + "-button"}
						>
						  {entry.title}
						</button>}
					{this.props.hasMenu && entry.subitems && 
						<button 
							onClick={() => this.setState({ activeUrl: entry.url})}
							className={"font-style-bold navbar-menu"+this.props.suffix+" navbar-button"+this.props.suffix+" "+this.props.type + this.props.suffix + "-button"}
						>
						  {entry.title}
						</button>}
					{(entry.subitems && (this.state.activeUrl===entry.url || this.props.activePath===entry.url)) && <NavBar activePath={this.props.activePath} fullPath={this.props.fullPath} items={entry.subitems} type={this.props.type} suffix={this.props.suffix + "-sub"} />}
				</li>
			  )}
			{this.props.loggedin === true && this.props.hasMenu && 
				<li className={"navbar-nav"+this.props.suffix+" "+this.props.type + this.props.suffix + "-nav"}>
						<button 
							onClick={() => navigate("/logout") }
							className={"font-style-bold navbar-menu"+this.props.suffix+" navbar-button"+this.props.suffix+" "+this.props.type + this.props.suffix + "-button"}
						>
						  {"Logout"}
						</button>
				</li>}
		</ul>
		);
	}
}

NavBar.propTypes = {
  loggedin: PropTypes.bool,
  suffix: PropTypes.string,
  fadeOption: PropTypes.string,
  hasMenu: PropTypes.bool
};

NavBar.defaultProps = {
  loggedin: false,
  hasMenu: false,
  fadeOption: `none`,
  suffix: ``
};


export default NavBar
