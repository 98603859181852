import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState, useEffect} from 'react';
import NavBarMenu from "../navbar/navbarmenu"

import loginSession from '../../../lib/class/loginSession'

import "./style.css"

import logo from "../../images/logo.png"
import logomobile from "../../images/logo-mobile.png"

import menulist from "../../content/headermenu.json"
import publicmenulist from "../../content/publicmenu.json"


import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

const Header = ({ displayType, activePath, pageTitle, placeHolder, noMenu }) => {
  const [isLogged, setIsLogged] = useState(null);
  const [authState, setAuthState] = useState();


  useEffect(()=>{
    var authentication = new loginSession()
    authentication.getToken().then((data)=>{
      setIsLogged(true)
    }).catch((err)=> {
      setIsLogged(false)
    });
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
    });
  },[])

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
  <header className={placeHolder?"header-placeholder":"header-freeze"}>
    <div className="header-background">
      <Link to={noMenu===false?"/": "/"+activePath} className="header-logo">
        <img src={ displayType >= 2 ? logomobile: logo} alt={data.site.siteMetadata.title} />
      </Link>
      { displayType >= 2 &&
        <span className={"header-title font-size-large"}>
          {pageTitle}
        </span>
      }
      { (placeHolder===false && noMenu===false) &&
          <NavBarMenu displayType={displayType}
            loggedin={isLogged || authState === AuthState.SignedIn}
            items={isLogged || authState === AuthState.SignedIn ? menulist : publicmenulist}
            type="header" activePath={activePath} />
      }
    </div>
  </header>
  )
}

Header.propTypes = {
  displayType: PropTypes.number,
  activePath: PropTypes.string,
  pageTitle: PropTypes.string,
  placeHolder: PropTypes.bool,
  noMenu: PropTypes.bool,
}

Header.defaultProps = {
  displayType: 0,
  placeHolder: false,
  activePath: ``,
  pageTitle: ``,
  noMenu: false,
}

export default Header
