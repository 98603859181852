import React, { Component } from 'react';
import PropTypes from "prop-types"
import {navigate} from 'gatsby'


import "./style.css"

import logout from "../../images/button/logout.png"
import menu from "../../images/menu.png"
import NavBar from "./navbar"



class NavBarMenu extends Component {
	state = {
		showMenu: false
	};
	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}
	handleResize = () => {
		this.setState(state => ({ showMenu: false}));
	};
	handleClick = () => {
		this.setState(state => ({ showMenu: !state.showMenu }));
	};
	render() {
		const navclass=this.props.displayType<2 ?"nav":"menu"
		return (
			<>
				<nav className={"navbar-"+navclass+" "+this.props.type + "-"+navclass} >
					{this.state.showMenu && <div role="button" tabIndex='0'  className="nav-shadow" onClick={this.handleClick} onKeyDown={this.handleClick}>&nbsp;</div>}
					{ navclass === "nav" ? 
						<NavBar items={this.props.items} type={this.props.type} activePath={this.props.activePath} fullPath={this.props.fullPath} /> 
					:
						<>
							<NavBar loggedin={this.props.loggedin} items={this.props.items} closeHandler={this.handleClick}  type={this.props.type} activePath={this.props.activePath} fullPath={this.props.fullPath} hasMenu={true} fadeOption={this.state.showMenu?"enter":"leave"} />
							<button className={"navbar-menu "+this.props.type + "-menu"} onClick={this.handleClick}>
								<img src={menu} alt="Menu" />
							</button>
						</>
					} 
				</nav>
		      { this.props.loggedin && navclass === "nav" &&
		        <button onClick={()=>{navigate("/logout");}} className="navbar-logout header-logout">
		          <img src={ logout} alt={"Logout"} />
		        </button>
		      }

			</>
		);
	}
}


NavBarMenu.propTypes = {
  loggedin: PropTypes.bool,
  displayType: PropTypes.number,
  activePath: PropTypes.string,
  fullPath: PropTypes.string,
  type: PropTypes.string,
  items: PropTypes.array
};

NavBarMenu.defaultProps = {
  loggedin: false,
  displayType: 0,
  activePath: ``,
  fullPath: ``,
  items: [],
  type: `header`
};

export default NavBarMenu
