import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ displayType, activePath }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)
  return (
  	<footer>
  		<span className="font-size-tiny">{data.site.siteMetadata.author} All Rights Reserved.</span>
  	</footer>
  )

}
Footer.propTypes = {
  displayType: PropTypes.number,
  activePath: PropTypes.string
}

Footer.defaultProps = {
  displayType: 0,
  activePath: ``
}

export default Footer
