
const awscognito = {
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: 'ap-southeast-1',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'ap-southeast-1_vjcakQoI3',
		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '7md35optu39jc3ri3mb56ohnmn',
		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: 'USER_SRP_AUTH',
		
        mandatorySignIn: true,
	}
};

export default awscognito;
