import React, { Component } from 'react';
import PropTypes from "prop-types"
import {Link} from 'gatsby'

import Header from "../header/"
import Footer from "../footer/"
import SEO from "../seo"

import "./style.css"
import "../../styles/colors.css"
import "../../styles/header.css"
import "../../styles/footer.css"
import "../../styles/navbar.css"


import backbtn from "../../images/button/back.png"

import config from '../../../config/aws-cognito'
import Auth from 'aws-amplify'

Auth.configure(config)

class Layout extends Component {
	state = {
		displayType: 0,
		width: 1024,
		height: 600
	};
	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		this.setState(state => ({ 
			displayType: 	(window.innerHeight<=0) ? 0: 
							(window.innerWidth/window.innerHeight)>1.5 ? 0:
							(window.innerWidth/window.innerHeight)>1 ? 1:
							(window.innerWidth/window.innerHeight)>0.8 ? 2:3,
			width: window.innerWidth,
			height: window.innerHeight
		}));
	};
	render() {
		return (
		    <>
		      <SEO title={this.props.pageTitle} description={this.props.pageDescription}  privatePage={this.props.privatePage} />
			  <Header placeHolder={true} displayType={this.state.displayType} />
		      <Header noMenu={this.props.noMenu} displayType={this.state.displayType} activePath={this.props.activePath} pageTitle={this.props.pageTitle} />
		      <div>
		        <main>{this.props.children?
		        	<>
						{ this.props.showtitle &&
							<h1 className="pagetitle">{this.props.showtitleback?<Link to={"../"} className={"pagetitle-back"}><img src={backbtn} alt={"Back"}/></Link>:""}{this.props.pageTitle}</h1>
						}	        	
			        	{this.props.children}
			        </>
		        :
		        	<p class="bannertext font-size-large">
					     {this.props.pageTitle} is Under Construction
					</p>
				}</main>
		      </div>
		      <Footer displayType={this.state.displayType} activePath={this.props.activePath} />
		    </>
		  );
	}
}


Layout.propTypes = {
  showtitleback: PropTypes.bool,
  showtitle: PropTypes.bool,
  children: PropTypes.node,
  activePath: PropTypes.string,
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string,
  noMenu: PropTypes.bool,
  privatePage: PropTypes.bool,
}


Layout.defaultProps = {
  showtitleback: false,
  showtitle: false,
  activePath: ``,
  pageTitle: ``,
  noMenu: false,
  privatePage: false
}


export default Layout
